<template>
  <div class="mapbox-gl-control eventline-control"
       :style="[offsetStyle, posStyle]">
    <v-menu v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            :left="attachLeft"
            offset-x
            z-index="10">
      <!-- Button -->
      <template #activator="{ on: menu }">
        <v-tooltip :left="attachLeft" :right="!attachLeft">
          <template #activator="{ on: tooltip }">
            <v-btn class="pa-1 ma-2"
               height="auto"
               min-width="0"
               :disabled="!allowedActive"
               :color="!selectedNone ? 'primary darken-1' : 'white'"
               small
               v-on="{ ...tooltip, ...menu }"
               @click="buttonClick">
              <v-icon>power_input</v-icon>
            </v-btn>
          </template>
          <span>
            <slot name="tooltip">Event lines</slot>
          </span>
        </v-tooltip>
      </template>

      <!-- Content -->
      <v-card outlined
              flat>
        <v-card-text>
          <slot />
          <v-layout :style="layoutStyle">
            <v-autocomplete :value="selectedOptions"
                            :items="options"
                            @change="updateSelectedOptions"
                            color="primary darken-1"
                            item-color="primary darken-1"
                            dense
                            chips
                            small-chips
                            label="Select lines"
                            multiple
                            clearable
                            hide-details
                            :loading="dataPending"
                            placeholder="None selected"
                            :open-on-clear="false"
                            filled>
              <!-- 'Select all' item -->
              <template #prepend-item
                        v-if="options.length > 1">
                <v-list-item ripple
                             @click="toggle">
                  <v-list-item-action>
                    <v-icon :color="selectedOptions.length > 0 ? 'primary darken-1' : ''">{{ icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>

              <!-- Custom selection -->
              <template #selection="{ parent, item, index }">
                <!-- Custom chip -->
                <v-chip v-if="index === 0"
                        :color="parent.itemColor"
                        :small="parent.smallChips">
                  <span class="ellipsis-chip-label">{{ item.text }}</span>
                  <v-icon v-if="parent.deletableChips"
                          small
                          right
                          @click="parent.selectItem(item)">cancel</v-icon>
                </v-chip>

                <!-- Appending text -->
                <span v-if="index === 1"
                      class="grey--text text-caption mr-1">(+{{ selectedOptions.length - 1 }} others)</span>
              </template>
            </v-autocomplete>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import menuCardStyleMixin from '@/utils/mixins/styling/menuCardStyle.mixin.js'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import mapboxLazyMixin from '@/utils/mixins/mapbox/mapboxLazy.mixin.js'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'pibot-mapbox-eventline-control',
  mixins: [positionStyleMixin, offsetStyleMixin, menuCardStyleMixin, mapboxBasicsMixin, mapboxLazyMixin],
  data () {
    return {
      initialized: false,
      menu: false,
      unwatchLayers: null
    }
  },
  computed: {
    ...mapState({
      selectedOptions: state => state.mapbox.selectedEventLines,
      dataPending: state => state.mapbox.dataPending,
      layersPending: state => state.mapbox.layersPending,
      legendsLoaded: state => state.legends.loaded,
      legends: state => state.legends.allLegends.filter(l => l.category === 'eventline'),
      mapFilter: state => state.mapbox.mapFilter
    }),
    ...mapGetters({
      options: 'mapbox/eventLineOptions',
      initialOptions: 'mapbox/initialEventLines',
      layerObjs: 'mapbox/layerObjs'
    }),
    selectedAll () {
      return this.selectedOptions.length === this.options.length
    },
    selectedSome () {
      return this.selectedOptions.length > 0 && !this.selectedAll
    },
    selectedNone () {
      return this.selectedOptions.length === 0
    },
    icon () {
      if (this.selectedAll) return 'check_box'
      if (this.selectedSome) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    },
    allowedActive () {
      if (this.options.length) return true
      return false
    }
  },
  methods: {
    ...mapActions({
      fetchLegends: 'legends/socket_fetchLegends'
    }),
    ...mapMutations({
      setSelectedOptions: 'mapbox/setSelectedEventLines',
      setActiveLegend: 'legends/setActiveLegend',
      removeActiveLegend: 'legends/removeActiveLegend'
    }),
    buttonClick () {
      if (!this.legendsLoaded.eventline) {
        this.fetchLegends('eventline').then(() => { })
      }
    },
    updateSelectedOptions (selectedOptions) {
      this.setSelectedOptions(selectedOptions)
      for (const option of this.options) {
        const willBeVisible = selectedOptions.includes(option.value)

        if (!willBeVisible) { // No need to lazy-load, continue as normal
          this.setLayerVisibility(option.value, willBeVisible)
          continue
        }

        // Handle lazy-loading
        this.lazyLoadSource(option.value)
          .then(() => {
            this.setLayerVisibility(option.value, willBeVisible)
          })
          .catch((error) => {
            console.error(error)
          })
      }
      if (this.selectedOptions.length > 0) {
        const legend = this.legends.find(l => l.type === 'eventline')
        if (legend) {
          this.setActiveLegend(legend)
        }
      } else {
        this.removeActiveLegend('eventline')
      }
    },
    toggle () {
      this.$nextTick(() => {
        let newValue

        if (this.selectedAll) newValue = []
        else newValue = this.options.map(o => o.value)

        this.updateSelectedOptions(newValue)
      })
    }
  },
  created () {
    /**
     * Set initially 'visible' options as selectedOptions on init
     * @see https://vuejs.org/v2/api/#vm-watch
     * @see https://stackoverflow.com/questions/46987893/vuejs2-how-can-i-destroy-a-watcher#answer-46988117
     */
    this.unwatchLayers = this.$watch('layersPending', (layersPending) => {
      if (!this.initialized && !layersPending) {
        this.setSelectedOptions(this.initialOptions)
        this.initialized = true

        // Unwatch after init
        if (this.unwatchLayers) this.unwatchLayers()
        else console.error('unable to \'unwatch\' \'layersPending\'')
      }
    }, {
      deep: true
    })
  }
}
</script>
